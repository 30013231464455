import * as React from "react"
import { Container, Row, Col} from "react-bootstrap"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { Icon } from "semantic-ui-react";

export default function About({location}) {
  
  const edu_color = "var(--secondary-color)";
  const work_color = "var(--primary-color)";
  
  return (
    <Layout lang='hu' location={location}>
      <SEO lang='hu' title="Rólam"/>
      <Container fluid className="h-100 d-flex flex-column">
        <VerticalTimeline className="flex-grow-1">
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: work_color, color: 'var(--secondary-color)'}}
            contentArrowStyle={{ borderRight: `7px solid  ${work_color}` }}
            date="2021 - mai napig"
            iconStyle={{ background: work_color, color: 'var(--secondary-color)' }}
            icon={<Icon name="suitcase" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Főorvos</h3>
            <h4 className="vertical-timeline-element-subtitle">Oberpullendorf Kórház Sebészeti Osztály</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: work_color, color: 'var(--secondary-color)'}}
            date="2017 - 2021"
            iconStyle={{ background: work_color, color: 'var(--secondary-color)'  }}
            icon={<Icon name="suitcase" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Sérvsebészeti Ambulancia vezetése</h3>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: work_color, color: 'var(--secondary-color)'}}
            date="2015 - 2021"
            iconStyle={{ background: work_color, color: 'var(--secondary-color)'  }}
            icon={<Icon name="suitcase" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Főorvos</h3>
            <h4 className="vertical-timeline-element-subtitle">Oberwart Oktató Kórház Sebészeti Osztály</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: work_color, color: 'var(--secondary-color)'}}
            date="2014 - 2015"
            iconStyle={{ background: work_color, color: 'var(--secondary-color)'  }}
            icon={<Icon name="suitcase" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Osztályvezető Főorvos</h3>
            <h4 className="vertical-timeline-element-subtitle">Tolna Megyei Balassa János Kórház Sebészeti Osztály</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: work_color, color: 'var(--secondary-color)'}}
            date="2002 - 2013"
            iconStyle={{ background: work_color,color: 'var(--secondary-color)'  }}
            icon={<Icon name="suitcase" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Szakorvos</h3>
            <h4 className="vertical-timeline-element-subtitle">Fejér Megyei Szent György Oktató Kórház</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2000 - 2002"
            contentStyle={{ background: edu_color, color: '#fff' }}
            contentArrowStyle={{ borderRight: `7px solid  ${edu_color}` }}
            iconStyle={{ background: edu_color, color: '#fff' }}
            icon={<Icon name="student" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Gastroenterologia szakorvosi képzés</h3>
            <h4 className="vertical-timeline-element-subtitle">Szeged Sebészeti Klinika</h4>
            <h4 className="vertical-timeline-element-subtitle">Fejér megyei Szent György Oktató Kórház Sebészeti Osztály</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="1994 - 2000"
            contentStyle={{ background: edu_color, color: '#fff' }}
            iconStyle={{ background: edu_color, color: '#fff' }}
            icon={<Icon name="student" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Általános sebészet szakorvosi képzés</h3>
            <h4 className="vertical-timeline-element-subtitle">Szeged Sebészeti Klinika / MJV Kórház Sebészeti Osztály</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="1988 - 1994"
            contentStyle={{ background: edu_color, color: '#fff' }}
            iconStyle={{ background: edu_color, color: '#fff' }}
            icon={<Icon name="student" className="vertical-timeline-custom-icon" size="large"/>}
          >
            <h3 className="vertical-timeline-element-title">Általános orvosi képzés</h3>
            <h4 className="vertical-timeline-element-subtitle">Szegedi Orvostudományi Egyetem</h4>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </Container>
    </Layout>
  )
}
